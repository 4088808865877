import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HotkeysCheatsheetComponent } from "./core/components/hotkeys/hotkeys-cheatsheet.component";

@Component({
  selector: 'cs-root',
  standalone: true,
  imports: [RouterOutlet, HotkeysCheatsheetComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
}
