import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserReadyGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    const userId = route.paramMap.get('id');
    if (!userId) {
      //redirect command
      return this.router.parseUrl('/not-found?error=missing-user-id');
    }
    const user = this.userService.get(userId);
    if (!user) {
      return this.router.parseUrl('/not-found?error=user-not-found');
    }
    return true;
  }
}
